/* You can add global styles to this file, and also import other style files */
/* Import UITK styles */
// @import '../node_modules/@uitk/angular/optum.css';

/*
• Primary palette used for screen elements
• Secondary palette used for charts and graphs
• Orange is used sparingly, for emphasis
• Backgrounds are white or pale gray
• All colors meet the WCAG 2.0 AA contrast requirements using the color contrast checker
*/

@import "../node_modules/@optum-recovery/ngx-common/src/styles.scss";
@import "../node_modules/@uitk/icons/webfonts/stylesheets/micro-interaction-icons.css";
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/primeflex/primeflex.scss";

// commonui
.p-picklist .p-picklist-list-wrapper {
  border: 1px solid #D0D0CE !important;
  height: 220px;
  overflow: auto;
}

.p-dropdown-clear-icon {
  top: 60% !important;
  right: 30px !important;
}

.imageHistoryTable .grid-primary-header {
  height: 0px !important;
}

.imageHistoryTable .grid-header .filter-section {
  height: 0px !important;
  padding: 0px 0 !important;
}

.p-accordion-tab.p-accordion-tab-active .p-toggleable-content {
  height: auto !important;
  overflow: unset !important;
}

body {
  color: var(--optumGrey1);
  font-weight: 400;
  overflow-x: hidden;
}

.nested-table-record-scroll {
  max-height: unset !important;
}

.nested-table {
  padding-right: unset !important;
  width: unset !important;
}
// commonui

/* PRIMARY COLOURS **/
$grey1: #282A2E;
$grey2: #434448;
$grey3: #63666A;
$grey4: #888B8D;
$grey5: #B1B2B3;
$grey6: #D0D0CE;
$grey7: #EFEFEE;
$grey8: #ECF2F9;
$white: #ffffff;
$accessibleOrange3: #C25608;
$optumBrandOrange: #E87722;
$optumBrandGold: #F2B411;
$goState: #627D32;
$errorState: #E32315;
$errorStateBackground: #FCE9E7;
$textLinkBlue: #005187;
$selectState: #DAE4F3;

/* SECONDARY */
$orange1: #492003;
$orange2: #793605;
$orange4: #EA8636;
$orange5: #F1B273;
$orange6: #F6CE9E;
$orange7: #FDF8DF;

$yellow1: #5A3605;
$yellow2: #855A09;
$yellow3: #C6900E;
$yellow4: #F3BC29;
$yellow5: #F6D270;
$yellow6: #F8E1A0;
$yellow7: #FBF8E7;

$green1: #2A3615;
$green2: #465924;
$green3: #6E8D3A;
$accentGreen2: #90BC53;
$green5: #B5D28C;
$green6: #CEE1B3;
$green7: #F3F8EC;

$teal1: #033933;
$teal2: #055F54;
$accentTeal1: #078576;
$teal3: #1C9182;
$teal4: #5AB5A5;
$accentTeal2: #6FC1B1;
$teal5: #93D1C5;
$teal6: #B7E0D8;
$teal7: #EDF7F5;

$blue1: #002843;
$blue2: #004370;
$blue3: #1965AE;
$blue4: #5686CA;
$blue5: #8FAEDC;
$blue6: #B4C9E7;
$blue7: #ECF2F9;

$purple1: #1C133A;
$purple2: #2F1F61;
$accentPurple1: #422C88;
$purple3: #523995;
$accentPurple2: #8061BC;
$purple5: #AA96D2;
$purple6: #C7B9E1;
$purple7: #F1EDF8;

$red1: #3D1011;
$red2: #661A1D;
$accentRed1: #A32A2E;
$accentRed2: #D13F44;
$red5: #E07F82;
$red6: #EBAAAC;
$red7: #FAEAEA;

/* BOW SHADOW */
@mixin drop-shadow($shadow: 0.1875rem 0.1875rem 0.1875rem 0 rgba(192, 192, 192, 0.75)) {
  box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  -webkit-box-shadow: $shadow;
}

@mixin inner-shadow($arguments: 0 0 0 0.0625rem, $color: $white) {
  box-shadow: inset $arguments $color;
  -moz-box-shadow: inset $arguments $color;
  -webkit-box-shadow: inset $arguments $color;
}

/* BORDER RADIUS */
@mixin rounded($radius: 0.125rem) {
  border-radius: $radius;
  -moz-border-radius: $radius;
  -webkit-border-radius: $radius;
}

/* GRADIENTS */
@mixin gradient($origin: bottom, $start: $grey7, $stop: $grey6 ) {
  background-color: $start;
  background-image: -webkit-linear-gradient($origin, $start, $stop);
  background-image: -moz-linear-gradient($origin, $start, $stop);
  background-image: -o-linear-gradient($origin, $start, $stop);
  background-image: -ms-linear-gradient($origin, $start, $stop);
  background-image: linear-gradient($origin, $start, $stop);
}

/* BOX SIZING */
@mixin box-sizing($type: border-box) {
  -webkit-box-sizing: $type;
  -moz-box-sizing: $type;
  box-sizing: $type;
}

/* TRANSITION */
@mixin transition($prop: top, $time: 0.5s, $ease: left) {
  -webkit-transition: $prop $time $ease;
  -moz-transition: $prop $time $ease;
  -o-transition: $prop $time $ease;
  -ms-transition: $prop $time $ease;
  transition: $prop $time $ease;
}

/* TRANSFORM */
@mixin transform($rotate: 0deg, $scale: 1, $skew: 0deg, $translate: 360deg) {
  -webkit-transform: rotate($rotate) scale($scale) skew($skew) translate($translate);
  -moz-transform: rotate($rotate) scale($scale) skew($skew) translate($translate);
  -o-transform: rotate($rotate) scale($scale) skew($skew) translate($translate);
  -ms-transform: rotate($rotate) scale($scale) skew($skew) translate($translate);
  transform: rotate($rotate) scale($scale) skew($skew) translate($translate);
}

app-login {
  width: 100%;
}

.background-size {
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-repeat: no-repeat;
}


.workflow-panel-sep {
  width: 100%;
  border-top-color: #ccc;
  border-top-width: 1px;
  margin-bottom: 20px;
  margin-top: 10px;
  border-top-style: solid;
}

.p-progress-spinner-circle {
  stroke: #C25608 !important;
}

td a {
  border-bottom: 1px solid;
}